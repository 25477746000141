<template>
  <div v-show="isShow" class="popup_layer" style="display: block">
    <div class="inner_popup_layer inner_fileadd_layer">
      <div class="layer_head">
        <strong class="tit_popup">등록된 첨부파일</strong>
      </div>
      <div class="layer_body">
        <!-- 2019-07-11_v2 ul > li 구조에서 div 구조로 수정 시작 -->
        <div class="list_fileadd">
          <div v-for="file in fileList" :key="file" class="group_addition">
            <a
              href="#x"
              class="link_down"
              title="파일 다운로드"
              @click.prevent="fileDownload(file.url)"
              >{{ file.name }}</a
            ><!-- href에 파일 경로 삽입 -->
          </div>
        </div>
        <!-- // 2019-07-11_v2 ul > li 구조에서 div 구조로 수정 끝 -->
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button type="button" class="btn_medium btn_primary" @click.prevent="closeLayer">
            확인
          </button>
        </div>
        <!-- <a href="javascript:void(0);" class="link_close" @click.prevent="closeLayer">
          <span class="ico_account">닫기</span>
        </a> -->
      </div>
    </div>
  </div>
</template>

<script>
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "CommAddedFileLayer",
  mixins: [CommLayerMixin],
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileList: [],
    };
  },
  computed: {
    ...mapGetters("CommStore", {
      layer: "getFirstLayer",
    }),
  },
  mounted() {
    this.isShow = this.layer.isShow;
  },
  updated() {
    this.fileList = this.layer.props.fileList;
  },
  methods: {
    closeLayer() {
      this._closeLayer(this.layer.order);
      this.$destroy();
    },
    fileDownload(_filePath) {},
  },
};
</script>
